<template>
  <a-modal
    title="新建"
    :width="1280"
    :visible="visible"
    :confirmLoading="loading"
    @ok="ok"
    @cancel="cancel"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="车牌">
              <a-input v-decorator="['number', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
              <car-plate-keyboard @submit="numbersubmit"></car-plate-keyboard>
            </a-form-item>
            <a-form-item label="事由">
              <a-input v-decorator="['reason', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="出口">
              <vxe-toolbar export print custom>
                <template v-slot:buttons>
                  <vxe-button @click="insertEvent">新增</vxe-button>
                  <vxe-button @click="$refs.xTable.removeCheckboxRow()">删除选中</vxe-button>
                </template>
              </vxe-toolbar>
              <vxe-table
                ref="xTable"
                :edit-config="{trigger: 'click', mode: 'cell'}"
                :data="tableData"
                class="my_table_insert"
              >
                <vxe-table-column type="checkbox" width="60"></vxe-table-column>
                <vxe-table-column field="category" title="一级分类" :edit-render="{name: 'select', options: exit_list, events: {change: categoryChangeEvent}}"></vxe-table-column>
                <vxe-table-column field="subcategory" title="二级分类" :edit-render="{name: 'select', options: father, props: {clearable: true}}"></vxe-table-column>
                <vxe-table-column field="name" title="名称" :edit-render="{name: 'input'}"></vxe-table-column>
                <vxe-table-column field="count" title="数量" :edit-render="{name: 'input'}"></vxe-table-column>
                <vxe-table-column field="unit" title="单位" :edit-render="{name: 'input'}"></vxe-table-column>
                <vxe-table-column field="remark" title="备注" :edit-render="{name: 'textarea'}"></vxe-table-column>
              </vxe-table>
            </a-form-item>
            <a-form-item
              v-for="(k, index) in form.getFieldValue('keys_entrance')"
              :key="index"
              v-bind="formItemLayoutWithOutLabel"
              :label="index === 0 ? '进口物品' : ''"
              :required="false"
            >
              <a-select
                placeholder="请选择物品一级类别"
                @change="handlecategoryChange"
                @select="handlecategoryEntranceSelect(k)"
                v-decorator="[
                  `entrance.category[${k}]`,
                  {
                    validateTrigger: ['change', 'blur'],
                    rules: [
                      {
                        required: true,
                        message: '请选择物品类别'
                      },
                    ],
                  },
                ]">
                <a-select-option v-for="item in entrance_list" :key="item.key" :value="item.key">
                  {{ item.title }}
                </a-select-option>
              </a-select>
              <a-select
                :selfUpdate="true"
                placeholder="请选择物品二级类别"
                v-decorator="[
                  `entrance.subcategory[${k}]`,
                  {
                    rules: [
                      {
                        required: false,
                        message: '请选择物品类别'
                      },
                    ],
                  },
                ]">
                <a-select-option v-for="son in father" :key="son.title" :value="son.key">
                  {{ son.title }}
                </a-select-option>
              </a-select>
              <a-input v-show="false" v-decorator="[`entrance.action[${k}]`, {initialValue: 3, rules: [{required: false, message: '必填项，请填写信息'}]}]" placeholder="请填写名称"></a-input>
              <a-input v-decorator="[`entrance.name[${k}]`, { rules: [{required: true, message: '必填项，请填写信息'}]}]" placeholder="请填写名称"></a-input>
              <a-input v-decorator="[`entrance.unit[${k}]`, { rules: [{required: true, message: '必填项，请填写信息'}]}]" placeholder="请填写货物单位"></a-input>
              <a-input v-decorator="[`entrance.count[${k}]`, { rules: [{required: true, message: '必填项，请填写信息'}]}]" placeholder="请填写货物数量"></a-input>
              <a-textarea v-decorator="[`entrance.remark[${k}]`, { rules: [{required: false, message: '必填项，请填写信息'}]}]" placeholder="请填写备注"></a-textarea>
              <a-icon
                v-if="form.getFieldValue('keys_entrance').length > 0"
                class="dynamic-delete-button"
                type="minus-circle-o"
                :disabled="form.getFieldValue('keys_entrance').length === 0"
                @click="() => remove(k)"
              />
            </a-form-item>
            <a-form-item v-bind="formItemLayoutWithOutLabel">
              <a-button type="dashed" style="width: 60%;" @click="add">
                <a-icon type="plus" /> 填写进口物品
              </a-button>
            </a-form-item>
            <a-form-item
              v-for="(k, index) in form.getFieldValue('keys_exit')"
              :key="k+100"
              v-bind="formItemLayoutWithOutLabel"
              :label="index === 0 ? '出口物品' : ''"
              :required="false"
            >
              <a-select
                placeholder="请选择物品一级类别"
                @change="handlecategoryChange"
                @select="handlecategoryExitSelect(k)"
                v-decorator="[
                  `exit.exitcategory[${k}]`,
                  {
                    validateTrigger: ['change', 'blur'],
                    rules: [
                      {
                        required: true,
                        message: '请选择物品类别'
                      },
                    ],
                  },
                ]">
                <a-select-option v-for="item in exit_list" :key="item.key+'10086'" :value="item.key">
                  {{ item.title }}
                </a-select-option>
              </a-select>
              <a-select
                placeholder="请选择物品二级类别"
                v-decorator="[
                  `exit.exitsubcategory[${k}]`,
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择物品类别'
                      },
                    ],
                  },
                ]">
                <a-select-option v-for="son in father" :key="son.title+'10086'" :value="son.key">
                  {{ son.title }}
                </a-select-option>
              </a-select>
              <a-input v-show="false" v-decorator="[`exit.action[${k}]`, {initialValue: 4, rules: [{required: false, message: '必填项，请填写信息'}]}]" placeholder="请填写名称"></a-input>
              <a-input v-decorator="[`exit.name[${k}]`, {rules: [{required: true, message: '必填项，请填写信息'}]}]" placeholder="请填写名称"></a-input>
              <a-input v-decorator="[`exit.unit[${k}]`, {rules: [{required: true, message: '必填项，请填写信息'}]}]" placeholder="请填写货物单位"></a-input>
              <a-input v-decorator="[`exit.count[${k}]`, {rules: [{required: true, message: '必填项，请填写信息'}]}]" placeholder="请填写货物数量"></a-input>
              <a-input v-decorator="[`exit.remark[${k}]`, {rules: [{required: false, message: '必填项，请填写信息'}]}]" placeholder="请填写备注"></a-input>
              <a-icon
                v-if="form.getFieldValue('keys_exit').length >0"
                class="dynamic-delete-button"
                type="minus-circle-o"
                :disabled="form.getFieldValue('keys_exit').length === 0"
                @click="() => remove_exit(k)"
              />
            </a-form-item>
            <a-form-item v-bind="formItemLayoutWithOutLabel">
              <a-button type="dashed" style="width: 60%;" @click="add_exit">
                <a-icon type="plus" /> 填写出口物品
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
// 表单字段
const fields = ['id']
let id = 0
let exit_id = 0
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      tableData: [],
      entrance: {},
      exit: {},
      entrance_list: this.$Dictionaries.GateWayItem,
      exit_list: this.$Dictionaries.GateWayItem,
      form: this.$form.createForm(this),
      father: this.$Dictionaries.GateWayItem[0].children,
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 2 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 }
        }
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13, offset: 7 }
        }
      }
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    this.form.getFieldDecorator('keys_entrance', { initialValue: [], preserve: true })
    this.form.getFieldDecorator('keys_exit', { initialValue: [], preserve: true })
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    numbersubmit (value) {
      const { form } = this
      form.setFieldsValue({ 'number': value })
    },
    categoryChangeEvent ({ row }) {
      // 类型切换时更新级联的下拉数据
      row.subcategory = ''
      console.log(typeof row.category)
      // eslint-disable-next-line eqeqeq
      this.father = this.$Dictionaries.GateWayItem.filter((item) => { return item.value == row.category })[0].children
    },
    insertEvent () {
      const xTable = this.$refs.xTable
      const newRow = { category: 1, subcategory: 1 }
      xTable.insert(newRow)
    },
    ok () {
       id = 0
       exit_id = 0
       this.$emit('ok')
    },
    cancel () {
      id = 0
      exit_id = 0
      this.$emit('cancel')
    },
    handlecategoryChange (value) {
      const { form } = this
      console.log(form.getFieldValue('entrance'), 'exit', value)
      this.father = this.$Dictionaries.GateWayItem.filter((item) => { return item.value === value })[0].children
    },
    handlecategoryEntranceSelect (k) {
      const { form } = this
      console.log(form.getFieldValue('entrance'), 'exit', k)
      const result = form.getFieldValue('entrance')
      result.subcategory[k] = undefined
      form.setFieldsValue({ 'entrance': result })
    },
    handlecategoryExitSelect (k) {
      const { form } = this
      console.log(form.getFieldValue('exit'), 'exit', k)
      const result = form.getFieldValue('exit')
      result.exitsubcategory[k] = undefined
      form.setFieldsValue({ 'exit': result })
    },
    remove (k) {
      console.log(k, '我是key')
      const { form } = this
      // can use data-binding to get
      const keys_entrance = form.getFieldValue('keys_entrance')
      // We need at least one passenger
      if (keys_entrance.length === 0) {
        return
      }

      // can use data-binding to set
      form.setFieldsValue({
        keys_entrance: keys_entrance.filter(key => key !== k)
      })
    },

    add () {
      const { form } = this
      // can use data-binding to get
      const keys_entrance = form.getFieldValue('keys_entrance')
      const nextkeys_entrance = keys_entrance.concat(id++)
      // can use data-binding to set
      // important! notify form to detect changes
      form.setFieldsValue({
        keys_entrance: nextkeys_entrance
      })
    },
    remove_exit (k) {
      const { form } = this
      // can use data-binding to get
      const keys_exit = form.getFieldValue('keys_exit')
      // We need at least one passenger
      if (keys_exit.length === 0) {
        return
      }

      // can use data-binding to set
      form.setFieldsValue({
        keys_exit: keys_exit.filter(key => key !== k)
      })
    },

    add_exit () {
      const { form } = this
      // can use data-binding to get
      const keys_exit = form.getFieldValue('keys_exit')
      const nextkeys_exit = keys_exit.concat(exit_id++)
      // can use data-binding to set
      // important! notify form to detect changes
      form.setFieldsValue({
        keys_exit: nextkeys_exit
      })
    }
  }
}
</script>
