<template>
  <div>
    <div >
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="4" :sm="24">
              <a-form-item label="企业">
                <a-auto-complete
                  v-model="queryParam.corporation"
                  :data-source="dataSource"
                  placeholder="请输入"
                  @select="onSelect"
                  @search="onSearch"
                  @change="onChange"
                />
                <span>{{ tip }}</span>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="时间">
                <a-range-picker @change="time" format="YYYY-MM-DD HH:mm:ss" v-model="queryParam.time">
                  <template slot="dateRender" slot-scope="current">
                    <div class="ant-calendar-date" :style="getCurrentStyle(current)" >
                      {{ current.date() }}
                    </div>
                  </template>
                </a-range-picker>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="状态">
                <a-select mode="tags" placeholder="请选择" @change="handleStatusChange" v-model="queryParam.gateWayStatus">
                  <a-select-option v-for="(value, key) in this.$Dictionaries.GateWayStatus" :value="key" :key="key">
                    {{ value }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <span class="table-page-search-submitButtons" >
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :pageNum="pageNum"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleLook(record)">详情</a>
            <a-divider type="vertical" v-if="record.status<3"/>
            <a-popconfirm
              v-if="record.status<3"
              title="确定要删除么？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelet(record)"
              v-action:delete
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </span>
      </s-table>
      <create-form
        v-if="visible"
        ref="createModal"
        :visible="visible"
        :loading="confirmLoading"
        :model="mdl"
        @cancel="handleAddCancel"
        @ok="handleOk"
      />
    </div>

  </div>
</template>

<script>
import { STable } from '@/components'
import { speedy_application_list, speedy_application_create, speedy_application_delete } from '@/api/speedy_application'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import { corporation_list } from '@/api/corporation'
/*
import pick from 'lodash.pick'
*/
export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      pageNum: 1,
      dataSource: [],
      corporation_id: null,
      tip: '',
      corList: [],
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      DrawerVisible: false,
      current_speedy_application_id: null,
      // 创建窗口控制
      visible: false,
      tags: [],
      selectedTags: [],
      mdl: {},
      is_show: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '企业',
          dataIndex: 'corporation',
          ellipsis: true
        },
        {
          title: '车牌号',
          dataIndex: 'number',
          align: 'center',
          ellipsis: true
        },
        {
          title: '事由',
          dataIndex: 'reason',
          align: 'center',
          ellipsis: true
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          align: 'center',
          ellipsis: true
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.GateWayStatus[text]
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return speedy_application_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      }
    }
  },
  created () {
    this.setCurrentPage()
  },
  methods: {
    setCurrentPage () {
      // const page = sessionStorage.getItem('CurrentPage')
      const page = this.$store.getters.pop
      if (page) {
        console.log(page)
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        } else {
          this.queryParam = {}
          this.$store.dispatch('clear')
        }
      }
    },
    onChange (value) {
      console.log(value)
    },
    onSearch (searchText) {
      corporation_list({ name: searchText }).then((res) => {
        const reslut = res.data.entries || []
        this.corList = reslut
        if (reslut.length === 0) {
          this.tip = '未找到您输入的企业'
        } else {
          this.tip = ''
        }
        console.log('我是企业列表', reslut)
        this.dataSource = !searchText ? [] : reslut.map((item) => item.name)
      })
    },
    onSelect (value) {
      console.log(value, '我是选择的值', this.corList)
      // eslint-disable-next-line eqeqeq
      this.queryParam.corporation_id = this.corList.filter((item) => (item.name === value) && item)[0].id

      console.log('onSelect', value, this.corList.filter((item) => (item.name === value) && item))
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    handleStatusChange (value) {
      console.log(`selected ${value}`)
      this.queryParam.statuses = value.join(',')
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      this.confirmLoading = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleDelet (record) {
      speedy_application_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')

        this.$refs.table.refresh()
      })
    },
    handleLook (record) {
      // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      // this.$emit('onLook', record)
      this.$router.push({ path: '/car/Quick/' + record.id + '/detail' })
    },
    afterVisibleChange (val) {
      console.log('visible', val)
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      let exit_item = []
      let entrance_item = []
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          values.number = values.number.toUpperCase()
           if (values.entrance && values.entrance.action.length > 0) {
             Object.keys(values.entrance).forEach(function (key) {
               values.entrance[key] = values.entrance[key].filter(n => n)
             })
             entrance_item = values.entrance.action.map((item, index, arr) => {
               return {
                 action: values.entrance.action[index] || '',
                 count: values.entrance.count[index] || '',
                 name: values.entrance.name[index] || '',
                 remark: values.entrance.remark[index] || '',
                 category: values.entrance.category[index] || '',
                 subcategory: values.entrance.subcategory[index] || '',
                 unit: values.entrance.unit[index] || ''
               }
            })
             console.log(values.entrance.action, '我是进口', entrance_item)
           }
          if (values.exit && values.exit.action.length > 0) {
            Object.keys(values.exit).forEach(function (key) {
              values.exit[key] = values.exit[key].filter(n => n)
            })
            exit_item = values.exit.action.map((item, index) => {
                return {
                  action: values.exit.action[index] || '',
                  count: values.exit.count[index] || '',
                  name: values.exit.name[index] || '',
                  remark: values.exit.remark[index] || '',
                  category: values.exit.exitcategory[index] || '',
                  subcategory: values.exit.exitsubcategory[index] || '',
                  unit: values.exit.unit[index] || ''
                }
            })
            console.log(values.exit.action, '我是出口', exit_item)
          }
          values.items = entrance_item.concat(exit_item)
            // 新增
          speedy_application_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            })
            .catch((error) => {
              console.log(error, 'err')
              this.confirmLoading = false
            })
          .finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {}
    }
  }
}
</script>
